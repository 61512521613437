import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from './notifications';


/*
 * GET gitlab
 */
export const getGitlab = () => {
  return dispatch => {
    dispatch(getGitlabStart())
    axios.get('/osiris/app/gitlab/projects')
      .then ( response => {
        dispatch(getGitlabSuccess(response.data))
      })
      .catch(error => {
        dispatch(getGitlabFail(error))
      })
  }
}

export const getGitlabStart = () => {
  return {
    type: actionTypes.GET_GITLAB_START,
  }
}

export const getGitlabSuccess = (res) => {
  return {
    type: actionTypes.GET_GITLAB_SUCCESS,
    res: res,
  }
}

export const getGitlabFail = (error) => {
  return {
    type: actionTypes.GET_GITLAB_FAIL,
    error: error,
  }
}


/*
 * GET caprover
 */
export const getCaprover = () => {
  return dispatch => {
    dispatch(getCaproverStart())
    axios.get('/osiris/app/caprover/apps')
      .then ( response => {
        dispatch(getCaproverSuccess(response.data))
      })
      .catch(error => {
        dispatch(getCaproverFail(error))
      })
  }
}

export const getCaproverStart = () => {
  return {
    type: actionTypes.GET_CAPROVER_START,
  }
}

export const getCaproverSuccess = (res) => {
  return {
    type: actionTypes.GET_CAPROVER_SUCCESS,
    res: res,
  }
}

export const getCaproverFail = (error) => {
  return {
    type: actionTypes.GET_CAPROVER_FAIL,
    error: error,
  }
}


/*
 *   POST DEPLOY APP
 */

export const deployApp = (payload) => {
  return dispatch => {
    dispatch(deployAppStart());
    dispatch(showNotification('Deployment gestartet', 'text'));
    axios.post('/osiris/app/caprover/deploy', payload)
      .then ( response => {
        dispatch(showNotification('Deployment erfolgreich'));
        dispatch(deployAppSuccess(response.data));
        dispatch(getCaprover()); //refresh deployed versions
      })
      .catch(error => {
        dispatch(showNotification('Deployment fehlgeschlagen', 'error'));
        dispatch(deployAppFail(error))
      });
  }
};

export const deployAppStart = () => {
  return {
    type: actionTypes.POST_DEPLOY_APP_START,
  };
};

export const deployAppSuccess = (res) => {
  return {
    type: actionTypes.POST_DEPLOY_APP_SUCCESS,
    res: res,
  };
};

export const deployAppFail = (error) => {
  return {
    type: actionTypes.POST_DEPLOY_APP_FAIL,
    error: error,
  };
};


/*
 *   POST GITLAB VARIABLES
 */

export const setGitlabVariables = (payload) => {
  return dispatch => {
    dispatch(setGitlabVariablesStart());
    axios.post('/osiris/app/gitlab/setVariables', payload)
      .then ( response => {
        dispatch(showNotification('Variablen gesetzt'));
        dispatch(setGitlabVariablesSuccess(response.data));
        dispatch(getGitlab()); //refresh gitlab settings
      })
      .catch(error => {
        dispatch(showNotification('Variablen fehlgeschlagen', 'error'));
        dispatch(setGitlabVariablesFail(error))
      });
  }
};

export const setGitlabVariablesStart = () => {
  return {
    type: actionTypes.POST_GITLAB_VARIABLES_START,
  };
};

export const setGitlabVariablesSuccess = (res) => {
  return {
    type: actionTypes.POST_GITLAB_VARIABLES_SUCCESS,
    res: res,
  };
};

export const setGitlabVariablesFail = (error) => {
  return {
    type: actionTypes.POST_GITLAB_VARIABLES_FAIL,
    error: error,
  };
};
