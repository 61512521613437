import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {createTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles'
import {Switch, Route, Redirect} from 'react-router-dom'
import {ConnectedRouter} from 'connected-react-router'
import './css/app.css'
import Header from './pages/components/Header'
import Navigation from './pages/components/Navigation'
import ViewLogin from './pages/views/ViewLogin'
import Notifications from './pages/components/Notifications'
import * as actions from './store/actions'
import ViewStart from './pages/views/ViewStart'
import ViewDeployments from './pages/views/ViewDeployments';


function App({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = createTheme({
    palette: {
      primary: {
        light: '#4b7093',
        main: '#2A4458',
        dark: '#3a5d7b',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#3a5d7b',
        main: '#2A4458',
        dark: '#11324e',
        contrastText: '#ffffff',
      },
    },
    typography: {
      //fontSize: 13,
    },
    //spacing: 6,
  });

  const checkAuth = {
    isAuthenticated: useSelector(state => state.login.isLoggedIn),
  };

  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          checkAuth.isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    )
  }

  const LoginRoute = ({ children, location, ...rest }) => {
    dispatch(actions.restoreSession())
    let newPath = '/app'
    if(location.state) newPath = location.state.from.pathname

    return (
      <Route
        {...rest}
        render={({ location }) =>
          checkAuth.isAuthenticated ? (
            <Redirect to={{ pathname: newPath }} />
          ) : (
            <ViewLogin />
          )
        }
      />
    )
  }

  return (
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <Switch>
            <LoginRoute path="/login" />
            <PrivateRoute path="/app">
              <Header/>
              <div className={classes.mainWindow}>
                <Navigation/>
                <Switch>
                  <Route path="/app/deployments">
                    <ViewDeployments />
                  </Route>
                  <Route path="/app">
                    <ViewStart />
                  </Route>
                </Switch>
              </div>
            </PrivateRoute>
            <Route path="/">
              <Redirect to={{ pathname: "/app/deployments" }} />
            </Route>
          </Switch>
          <Notifications />
        </div>
      </MuiThemeProvider>
    </ConnectedRouter>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  mainWindow: {
    display: 'flex',
    padding: 20,
    paddingTop: 30,
  }
}));

export default App;
