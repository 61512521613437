import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"
import * as actions from '../../store/actions'
import MenuIcon from '@material-ui/icons/Menu'
import LoginIcon from '@material-ui/icons/Person'
import logoImg from '../../assets/osiris_logo_01_400.png'

const Header = (props) => {

  const classes = useStyles(props)
  const dispatch = useDispatch()

  const isLoggedIn = useSelector(state => state.login.isLoggedIn)

  const handleDrawerOpen = () => {
    dispatch(actions.openDrawer())
  }

  const HamburgerButton = () => {
    return(
      <IconButton
        edge="start"
        color="inherit"
        onClick={handleDrawerOpen}
        aria-label="menu">
        <MenuIcon/>
      </IconButton>
    )
  }

  const LoginButton = () => {
    const url = '/login'
    return(
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => window.location.href=url}
        aria-label="login">
        <LoginIcon/>
      </IconButton>
    )
  }

  return (
    <div>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <div className={classes.portalLogoHeadline}>
            <img src={logoImg} alt="Logo" className={classes.tenantLogo}/>
          </div>
          <Typography className={classes.spacer}> </Typography>
          {
            isLoggedIn ? <div className={classes.menuButton}><HamburgerButton /></div> : <LoginButton />
          }
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  )


}

const useStyles = makeStyles(theme => ({
  tenantLogo: {
    objectFit: 'contain',
    width: 135,
    height: 44,
    [theme.breakpoints.up('md')]: {
      width: 200,
      height: 65,
    }
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBar: {
    zIndex: 5000,
    position: 'fixed',
    minHeight: 30,
    backgroundColor: '#2A4458',
  },
  spacer: {
    flexGrow: 1,
  },
  portalLogoHeadline: {
    fontSize: 13,
    paddingTop: 6,
    paddingBottom: 6,
    '& a': {
      color: '#000'
    },
    '& a:visited': {
      color: '#000'
    }
  }
}))


export default Header
