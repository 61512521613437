import React, {useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {makeStyles, Grid, Box} from '@material-ui/core'
import {CloudQueue, Refresh} from '@material-ui/icons';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import DeploymentsTable from '../tables/DeploymentsTable'
import IconButton from '@material-ui/core/IconButton';


const ViewDeployments = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getGitlab())
    dispatch(actions.getCaprover())
  },[dispatch])

  const isLoading = useSelector(state => state.deployment.isLoading)

  const refresh = () => {
    dispatch(actions.getGitlab())
    dispatch(actions.getCaprover())
  }
  return (
    <div className={classes.root}>
      <Box display="flex" className="headlineBox">
        <Box display="flex" alignItems="center" className="headlineIconBox">
          <CloudQueue fontSize={'small'} style={{ color: '#838383' }} />
          <div className="headlineIconText">Deployments</div>
        </Box>
        <Box display="flex" alignItems="center">Manage apps and servers &nbsp;
          <IconButton size="small" onClick={refresh}>
            <Refresh />
          </IconButton>

        </Box>
      </Box>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <DeploymentsTable /> }
        </Grid>
      </Grid>


    </div>
  )
})

const useStyles = makeStyles(theme => ({
  padding10: {
    padding: 10,
  },
  active: {
    color: '#404040',
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: 3
  },
  inactive: {
    color: '#929292',
    paddingLeft: 5,
    paddingRight: 5,
    marginRight: 3
  },
  paper: {
    minWidth: 400,
    padding: 20
  },
  paperTitle: {
    fontWeight: 800
  }


}))

export default ViewDeployments
