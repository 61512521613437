import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import commitVersion from '../../commitVersion.json';

export const getVersion = () => {
  return dispatch => {
    dispatch(getVersionStart());
    axios.get('/info/version/osiris')
      .then ( response => {
        dispatch(getVersionSuccess(response.data));
        checkVersion(response.data, dispatch);
      })
      .catch(error => {
        dispatch(getVersionFail(error))
      });
  }
};

const checkVersion = (data, dispatch) => {
  //console.log(`${process.env.REACT_APP_VERSION}`)
  if(data.commit_sha && commitVersion.commit_sha) {
    if(data.commit_sha !== commitVersion.commit_sha) {
      console.log("Version NOK - update necessary")
      window.location.reload();
    }
  }
}

export const getVersionStart = () => {
  return {
    type: actionTypes.GET_VERSION_START,
  };
};

export const getVersionSuccess = (res) => {
  return {
    type: actionTypes.GET_VERSION_SUCCESS,
    res: res,
  };
};

export const getVersionFail = (error) => {
  return {
    type: actionTypes.GET_VERSION_FAIL,
    error: error,
  };
};
