import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import './css/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import navigationReducer from './store/reducers/navigation'
import loginReducer from './store/reducers/login'
import mainWindowReducer from './store/reducers/mainWindow'
import deploymentReducer from './store/reducers/deployment'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  navigation: navigationReducer,
  login: loginReducer,
  mainWindow: mainWindowReducer,
  deployment: deploymentReducer,
})

const history = createBrowserHistory()

const store = createStore(rootReducer(history), composeEnhancers(
  applyMiddleware(
    routerMiddleware(history),
    thunk
  )
))

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App history={history}/>
    </ConnectedRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
