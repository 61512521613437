import React from 'react';
import {useDispatch} from 'react-redux';
import * as actions from '../../store/actions';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core';
import portalLogo from '../../assets/osiris_logo_01.png';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

const ViewLogin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [values, setValues] = React.useState({
    email:'',
    password:''
  })

  const loginHandler = (event) => {
    event.preventDefault();
    dispatch(actions.login(values));
    return true;
  };

  const inputChangedHandler = (event) => {
  setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  };

  return (
    <Grid container spacing={0} className={classes.padding10}>
      <Grid container item xs={12} justifyContent="center">
        <div className={classes.logoBox}>
          <div>
            <img src={portalLogo} alt="Blau3 Osiris" className={classes.logo} />
          </div>
        </div>

        <Grid container spacing={0}>
          <Grid container item xs={12}
                alignItems="center"
                justifyContent="center">
            <form id="loginform" autoComplete="on" onSubmit={loginHandler}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Login
                  </Typography>
                  <TextField
                    autoFocus
                    id="email"
                    name="email"
                    type="text"
                    label={"e-Mail"}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => inputChangedHandler(event)}
                    className={classes.textField}
                    margin="normal"
                  />
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    label={"Passwort"}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => inputChangedHandler(event)}
                    className={classes.textField}
                    margin="normal"
                  />
                </CardContent>
                <CardActions className={classes.buttonBox}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.button}
                  >
                    Anmelden
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  padding10: {
    padding: 10,
  },
  logo: {
    width: 300,
    marginTop: 20,
  },
  logoBox: {
    display: 'block',
    textAlign: 'center',
  },
  card: {
    margin: 40,
  },
  textField: {
    width: '100%',
    marginBottom: 5,
  },
  buttonBox: {
    display: 'block',
    textAlign: 'center',
    marginBottom: 15
  },
  button: {
    width: '70%',
  }
}));

export default ViewLogin;
