import React, {forwardRef} from 'react'
import {
  AddBox, ArrowDownward,
  Check, ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FirstPage,
  LastPage, Remove,
  SaveAlt, Search, ViewColumn
} from '@material-ui/icons'
import FilterList from '../../assets/filterIcon.svg'

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <img src={FilterList} style={{width:18}} {...props} ref={ref} alt="Filter" />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

export const localization={
  body: {
    emptyDataSourceMessage: 'No entries',
      addTooltip: 'Add',
      deleteTooltip: 'Delete',
      editTooltip: 'Edit',
      filterRow: {
      filterTooltip: 'Filter'
    },
    editRow: {
      deleteText: 'Really delete?',
        cancelTooltip: 'Cancel',
        saveTooltip: 'Save'
    }
  },
  grouping: {
    placeholder: 'Columns ...',
      groupedBy: 'Group by:'
  },
  header: {
    actions: 'Actions'
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} of {count}',
      labelRowsSelect: 'Rows',
      labelRowsPerPage: 'Rows per page:',
      firstAriaLabel: 'First page',
      firstTooltip: 'First page',
      previousAriaLabel: 'Previous page',
      previousTooltip: 'Previous page',
      nextAriaLabel: 'Next page',
      nextTooltip: 'Next page',
      lastAriaLabel: 'Last page',
      lastTooltip: 'Last page'
  },
  toolbar: {
    addRemoveColumns: 'Add or remove columns',
      nRowsSelected: '{0} Row(s) selected',
      showColumnsTitle: 'Show Columns',
      showColumnsAriaLabel: 'Show Columns',
      exportTitle: 'Export',
      exportAriaLabel: 'Export',
      exportName: 'Export as CSV',
      searchTooltip: 'Search',
      searchPlaceholder: 'Search'
  }
}
