import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoggedIn: false,
  lastLocation: null,
  user: {
    name: '',
  },
  version: {
    build_date: '',
    commit_sha: '',
    version: '',
  }
};

const setLogin = (state, action, isLoggedIn) => {
  return {
    ...state,
    isLoggedIn: true,
    user: action.res,
  }
};

const setLogout = (state) => {
  return {
    ...state,
    isLoggedIn: false,
    user: initialState.user,
  }
};

const setVersion = (state, action) => {
  return {
    ...state,
    version: action.res,
  }
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.LOGIN_SUCCESS: return setLogin(state, action);
    case actionTypes.RESTORE_SESSION_SUCCESS: return setLogin(state, action);
    case actionTypes.LOGOUT_SUCCESS: return setLogout(state);
    case actionTypes.GET_VERSION_SUCCESS: return setVersion(state, action);
    default: return state;
  }
};

export default reducer;
