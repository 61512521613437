import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import {makeStyles} from '@material-ui/core';

export const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  )

}

const useStyles = makeStyles(theme => ({
  loading: {
    margin: 20,
  }
}));
