import * as actionTypes from './actionTypes'

/*
 * Drawer state
 */
export const openDrawer = () => {
  return {
    type: actionTypes.OPEN_DRAWER,
  }
}

export const closeDrawer = () => {
  return {
    type: actionTypes.CLOSE_DRAWER,
  }
}
