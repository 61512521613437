export const OPEN_DRAWER = 'OPEN_DRAWER'
export const CLOSE_DRAWER = 'CLOSE_DRAWER'
export const NAVIGATE_PAGE = 'NAVIGATE_PAGE'

export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGOUT_START = 'LOGOUT_START'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const RESTORE_SESSION_START = 'RESTORE_SESSION_START'
export const RESTORE_SESSION_SUCCESS = 'RESTORE_SESSION_SUCCESS'
export const RESTORE_SESSION_FAIL = 'RESTORE_SESSION_FAIL'

export const UPLOAD_START = 'UPLOAD_START'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const UPLOAD_FAIL = 'UPLOAD_FAIL'

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'

export const GET_VERSION_START = 'GET_VERSION_START'
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS'
export const GET_VERSION_FAIL = 'GET_VERSION_FAIL'

export const GET_GITLAB_START = 'GET_GITLAB_START'
export const GET_GITLAB_FAIL = 'GET_GITLAB_FAIL'
export const GET_GITLAB_SUCCESS = 'GET_GITLAB_SUCCESS'

export const GET_CAPROVER_START = 'GET_CAPROVER_START'
export const GET_CAPROVER_FAIL = 'GET_CAPROVER_FAIL'
export const GET_CAPROVER_SUCCESS = 'GET_CAPROVER_SUCCESS'

export const POST_DEPLOY_APP_START = 'POST_DEPLOY_APP_START'
export const POST_DEPLOY_APP_FAIL = 'POST_DEPLOY_APP_FAIL'
export const POST_DEPLOY_APP_SUCCESS = 'POST_DEPLOY_APP_SUCCESS'

export const POST_GITLAB_VARIABLES_START = 'POST_GITLAB_VARIABLES_START'
export const POST_GITLAB_VARIABLES_FAIL = 'POST_GITLAB_VARIABLES_FAIL'
export const POST_GITLAB_VARIABLES_SUCCESS = 'POST_GITLAB_VARIABLES_SUCCESS'
