import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  gitlab: [],
  caprover: [],
}

const setGitlab = (state, action) => {
  return {
    ...state,
    gitlab: action.res,
    isLoading: false,
  }
}

const setCaprover = (state, action) => {
  return {
    ...state,
    caprover: action.res,
  }
}

const startLoading = (state) => {
  return {
    ...state,
    isLoading: true,
  }
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_GITLAB_SUCCESS: return setGitlab(state, action)
    case actionTypes.GET_CAPROVER_SUCCESS: return setCaprover(state, action)
    case actionTypes.GET_GITLAB_START: return startLoading(state, action)
    default: return state
  }
}

export default reducer
