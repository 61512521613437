import React from 'react';
import {formatDateTime2} from '../../functions/unitFormater';
import {makeStyles} from '@material-ui/core/styles';
import {getObjects} from '../../functions/arrayHelper';
import {Tooltip} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {FiberManualRecord, HighlightOff} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import {deployApp} from '../../store/actions';

export const DeploymentTableDetails = (props) => {
  const {rowData} = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const caprover = useSelector(state => state.deployment.caprover)


  // sort all images to show newest docker images first
  let dockerSorted = []
  if(rowData.docker.length) {
    dockerSorted = rowData.docker.sort((a,b) => (a.commitDetails.createdAt > b.commitDetails.createdAt)
      ? -1
      : ((b.commitDetails.createdAt > a.commitDetails.createdAt) ? 1 : 0))
  }

  const handleDeploy = (payload) => {
    dispatch(deployApp(payload))
  }

  // check given stage with commitID to show color indicator or deploy option
  const checkCommitDeployment = (stage, rowData, commit) => {
    const appName = rowData.name + '-' + stage
    const appResult = getObjects(caprover, 'appName', appName)
    const commitResult = getObjects(appResult, 'commit', commit)
    return(<div>
      {appResult.length >= 1
        ? commitResult.length >= 1
          ? <Tooltip title={formatDateTime2(commitResult[0].deployedVersion.timeStamp)} enterTouchDelay={0}>
              <IconButton>
                <FiberManualRecord style={{ color: '#4db300'}}/>
              </IconButton>
            </Tooltip>
          : <Tooltip title={'DEPLOY'} enterTouchDelay={0}>
              <IconButton onClick={() => handleDeploy({appName:rowData.name, stage:stage, commitSha: commit})}>
                <FiberManualRecord style={{ color: 'gray'}}/>
              </IconButton>
            </Tooltip>
        : <Tooltip title={'Stage offline'} enterTouchDelay={0}>
            <IconButton>
              <HighlightOff style={{ color: 'gray', height: 21 }}/>
            </IconButton>
          </Tooltip>
      }
    </div>)
  }

  return(
    <div
      style={{
        backgroundColor: '#ecf7fc',
        padding: 8,
      }}
    >
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.mediumColumn}>CreatedAt</th>
            <th>Title</th>
            <th className={classes.mediumColumn}>CommitId</th>
            <th className={classes.smallColumn}>Dev</th>
            <th className={classes.smallColumn}>Test</th>
            <th className={classes.smallColumn}>Prod</th>
          </tr>
        </thead>
        <tbody>
          {dockerSorted.map(x => {
            return <tr key={x.name}>
              <td>{formatDateTime2(x.commitDetails.createdAt)}</td>
              <td>{x.commitDetails.title}</td>
              <td><a
                href={`https://gitlab.com/blau3/${rowData.name}/-/commit/${x.name}`}
                target="_blank" rel="noreferrer">{x.name.slice(0,8)}</a>
              </td>
              <td>{checkCommitDeployment('dev', rowData, x.name)}</td>
              <td>{checkCommitDeployment('test', rowData, x.name)}</td>
              <td>{checkCommitDeployment('prod', rowData, x.name)}</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  )
}



const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    border: '1px solid #3276A7',
    '& td': {
      border: '1px solid #3276A7',
      fontSize: 13,
      fontWeight: 200,
      padding: 4,
    },
    '& th': {
      color: '#3276A7',
      fontSize: 13,
      fontWeight: 500,
      border: '1px solid #3276A7',
      padding: 4,
    },
  },
  smallColumn: {
    width: 50,
  },
  mediumColumn: {
    width: 90,
  }
}))
