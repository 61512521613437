import * as actionTypes from '../actions/actionTypes';

const initialState = {
  drawerState: false,
};

const setDrawerState = (state, action) => {
  return {
    ...state,
    drawerState: action,
  }
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.OPEN_DRAWER: return setDrawerState(state, true);
    case actionTypes.CLOSE_DRAWER: return setDrawerState(state, false);
    default: return state;
  }
};

export default reducer;
