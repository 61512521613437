import React from 'react'
import {withRouter} from 'react-router-dom'
import {Box, makeStyles, Paper} from '@material-ui/core';
import {Home} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import * as actions from '../../store/actions';
import {useDispatch} from 'react-redux';

const ViewStart = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(actions.showNotification('Hello World'))
  }

  return (
    <div>
      <Box display="flex" className="headlineBox">
        <Box display="flex" alignItems="center" className="headlineIconBox">
          <Home fontSize={'small'} style={{ color: '#838383' }} />
          <div className="headlineIconText">Start</div>
        </Box>
        <Box display="flex" alignItems="center">Blau3 Admin</Box>
      </Box>

      <div>
        <Paper className={classes.padding10}>
          <p>Dashboard coming soon</p>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Test
          </Button>
        </Paper>

      </div>
    </div>
  )
})

const useStyles = makeStyles(theme => ({
  padding10: {
    padding: 10,
  },
  paper: {
    minWidth: 400,
    padding: 20
  },
  buttonBox: {
    display: 'block',
    textAlign: 'center',
    marginBottom: 15
  },
  button: {
    width: '70%',
  }
}))

export default ViewStart
