import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import Typography from '@material-ui/core/Typography'
import {formatDateTime, formatDateTime2} from '../../functions/unitFormater';
import {makeStyles, Tooltip} from '@material-ui/core';
import {localization, tableIcons} from '../components/TableSharedConfig'
import {getObjects} from '../../functions/arrayHelper';
import {DeploymentTableDetails} from './DeyplomentTableDetails';
import {setGitlabVariables} from '../../store/actions';
import {
  Cloud,
  CloudDone,
  EnhancedEncryption,
  Https,
  NoEncryption,
  PlaylistAdd,
  PlaylistAddCheck
} from '@material-ui/icons';

const DeploymentsTable = withRouter((props) => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const data = useSelector(state => state.deployment.gitlab)
  const caprover = useSelector(state => state.deployment.caprover)

  const handleSetGitlabVariables = (payload) => {
    dispatch(setGitlabVariables(payload))
  }

  const name = {
    title: 'Name',
    field: 'name',
    //defaultSort: 'asc',
    render: rowData => <div className={classes.wideColumn}>{rowData.name}</div>
  }

  const images = {
    title: 'Images',
    field: 'docker',
    render: rowData => <div>{rowData.docker.length}</div>
  }

  const checkStageDeployment = (stage, rowData) => {
    const appName = rowData.name + '-' + stage
    const capInstance = getObjects(caprover, 'appName', appName)
    return(<div className={classes.narrowColumn}>
        {capInstance.length >= 1
          ? <div>
              <Tooltip title={<div>
                  <p className={'tooltipAction'}>Open CapRover</p>
                  <p>Container online <br/>{formatDateTime2(capInstance[0].deployedVersion.timeStamp)}</p>
                  <ul>{capInstance[0].envVars.map(v => <li key={v.key}>{v.key} = {v.value}</li>)}</ul>
                </div>} enterTouchDelay={0}>
                <a
                  href={`https://captain.cloud.blau3.com/#/apps/details/${appName}`}
                  target="_blank"
                  rel="noreferrer">{
                    capInstance[0].envVars.length >= 1
                      ? <CloudDone className={classes.icon}/>
                      : <Cloud className={classes.icon}/>
                  }
                </a>
              </Tooltip>
              <a
                href={capInstance[0].url}
                target="_blank"
                rel="noreferrer">{
                capInstance[0].https
                  ? capInstance[0].forceHttps
                  ?  <Tooltip title={<div><p className={'tooltipAction'}>Open App Url</p>Caprover: Force HTTPS</div>} enterTouchDelay={0}>
                      <EnhancedEncryption className={classes.icon} /></Tooltip>
                    : <Tooltip title={<div><p className={'tooltipAction'}>Open App Url</p>Caprover: HTTPS enabled</div>} enterTouchDelay={0}>
                        <Https className={classes.icon} /></Tooltip>
                  : <Tooltip title={<div><p className={'tooltipAction'}>Open App Url</p>Caprover: HTTP only</div>} enterTouchDelay={0}>
                      <NoEncryption className={classes.iconToDo} /></Tooltip>
                }
              </a>
            </div>
          : <div>-</div>
        }
    </div>)
  }

  const checkGitlabConfig = (rowData) => {
    const caproverVariables = getObjects(rowData.projectVariables, 'key', 'CAPROVER_URL')
    const allGitlabVariables = rowData.projectVariables.length >= 1 ? rowData.projectVariables : []
    return(
      caproverVariables.length >= 1
      ? <Tooltip title={<div>
          <ul>{allGitlabVariables.map(v => <li key={v.key}>{v.key} = {v.value.slice(0,75)}</li>)}</ul>
        </div>} enterTouchDelay={0}><PlaylistAddCheck className={classes.icon} /></Tooltip>
        : <Tooltip title={<div><p className={'tooltipAction'}>Set Gitlab Variables</p>
            <ul>{allGitlabVariables.map(v => <li key={v.key}>{v.key} = {v.value.slice(0,75)}</li>)}</ul>
          </div>} enterTouchDelay={0}>
          <PlaylistAdd className={classes.iconToDo} onClick={() => handleSetGitlabVariables({appName:rowData.name})} />
        </Tooltip>
    )
  }

  const dev = {
    title: 'Dev',
    sorting: false,
    render: rowData => checkStageDeployment('dev', rowData)
  }

  const test = {
    title: 'Test',
    sorting: false,
    render: rowData => checkStageDeployment('test', rowData)
  }

  const prod = {
    title: 'Prod',
    sorting: false,
    render: rowData => checkStageDeployment('prod', rowData)
  }

  const id = {
    title: 'Gitlab',
    field: 'id',
    render: rowData => <div className={classes.href}>
      <a href={`https://gitlab.com/blau3/${rowData.name}`} target="_blank" rel="noreferrer">{rowData.id}</a>
      &nbsp; {checkGitlabConfig(rowData)}
    </div>,
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div className={classes.mediumColumn}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    defaultSort: 'desc',
  }

  const columnSet = [
    name,
    dev,
    test,
    prod,
    images,
    id,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={null}
        options={{
          search: true,
          filtering: false,
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: -1,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          cellStyle:{ padding: '6px 6px 0px 6px'},
        }}
        localization={localization}
        detailPanel={[
          {
            tooltip: 'Show Name',
            render: rowData => <div><DeploymentTableDetails rowData={rowData} /></div>,
          }
        ]}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </Typography>
  )
})

const useStyles = makeStyles(theme => ({
  description: {
    whiteSpace: 'pre-wrap',
  },
  txtArea: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '13px',
  },
  txtField: {
    '& input': {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '13px',
      textAlign: 'right',
    },
  },
  wideColumn: {
    width: 250
  },
  mediumColumn: {
    width: 125
  },
  narrowColumn: {
    width: 60
  },
  href: {
    '& a': {
      color: '#0c598b'
    },
    '& a:hover': {
      color: '#1c94e0'
    },
  },
  icon: {
    color: '#c5cec5',
    '&:hover': {
      color: '#889288',
    }
  },
  iconToDo: {
    color: '#cba7a2',
    '&:hover': {
      color: '#a37b76',
    }
  },
}))

export default DeploymentsTable
