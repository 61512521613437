// 30. Aug. 2020
import NumberFormat from 'react-number-format';
import React from 'react';

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('de-DE', options)
};

// 30.08.2020, 18:59
export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleDateString('de-DE', options)
};

// 30. Aug. 2020, 18:59
export const formatDateTime2 = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleDateString('de-DE', options)
};

export const formatBytes = (a,b) => {if(0===a)return"0 Bytes";let c=1024,d=b||2,e=["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"],
  f=Math.floor(Math.log(a)/Math.log(c));return parseFloat((a/Math.pow(c,f)).toFixed(d))+" "+e[f]}


export const NumberFormatEuro = (props) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale="2"
      fixedDecimalScale
      suffix=" €"
    />
  )
}

export const NumberFormatPercent = (props) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale="1"
      fixedDecimalScale
      suffix=" %"
    />
  )
}
