import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom'
import {
  Drawer, List, Divider, ListItem, ListItemIcon, ListItemText, Hidden, Toolbar, Box
} from '@material-ui/core';
import {
  ChevronRight, PeopleOutline, ExitToApp, Home, CloudQueue
} from '@material-ui/icons';
import * as actions from '../../store/actions';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const Navigation = () => {

  const classes = useStyles()
  const dispatch = useDispatch()

  const drawerState = useSelector(state => state.navigation.drawerState)
  const user = useSelector(state => state.login.user)
  const version = useSelector(state => state.login.version)

  const handleDrawerClose = () => {
    dispatch(actions.closeDrawer())
  }

  const handleLogout = () => {
    dispatch(actions.logout())
  }

  const DrawerContent = withRouter((props) => {
    return (
      <div>
        { props.mobile
          ? <div>
              <List>
                <ListItem>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={handleDrawerClose}
                    startIcon={<ChevronRight />}
                  >
                    zurück
                  </Button>
                </ListItem>
              </List>
              <Divider />
            </div>
          : null
        }

        <Box display="flex" alignItems="center" style={{ fontSize: 12, backgroundColor: '#6e7379', padding: 0, paddingLeft: 8, paddingTop: 7, color: '#FFFFFF'}}>
          Hey&nbsp;<b>{user.name}</b>
          <div style={{flexGrow: 1, flexFlow: 'row nowrap'}}>
            <div style={{display: 'flex', marginLeft: 10, justifyContent: 'flex-end'}}>
              <Tooltip title="Benutzerprofil" enterTouchDelay={0}>
                <IconButton onClick={() => {
                  props.history.push('/app/profile'); handleDrawerClose()
                }}><PeopleOutline style={{width: 20, color: '#FFFFFF'}} /></IconButton>
              </Tooltip>
              <Tooltip title="Logout" enterTouchDelay={0}>
                <IconButton onClick={() => {
                  handleLogout();
                }}><ExitToApp style={{width: 20, color: '#FFFFFF'}} /></IconButton>
              </Tooltip>
            </div>
          </div>
        </Box>

        <List>
          <ListItem button onClick={() => { props.history.push('/app/'); handleDrawerClose() }}>
            <ListItemIcon><Home /></ListItemIcon>
            <ListItemText primary={"Start"} />
          </ListItem>
          <ListItem button onClick={() => { props.history.push('/app/deployments'); handleDrawerClose() }}>
            <ListItemIcon><CloudQueue /></ListItemIcon>
            <ListItemText primary={"Deployments"} />
          </ListItem>
          <Divider />
        </List>

        <div className={classes.version}>
          <small>Version: {version.version || 'dev0'}</small>
        </div>
      </div>
    )
  })

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={drawerState}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <DrawerContent mobile={true} />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css" className={classes.drawerPaperPermanent}>
        <Drawer
          classes={{
            paper: classes.drawerPaperPermanent,
          }}
          variant="permanent"
          open
        >
          <Toolbar className={classes.spacer} />
          <DrawerContent mobile={false} />
        </Drawer>
      </Hidden>
    </div>
  )


}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: '90%',
    backgroundColor: '#e3e3e3',
  },
  drawerPaperPermanent: {
    width: 265,
    flexShrink: 0,
    backgroundColor: '#e3e3e3',
  },
  p10: {
    paddingTop: 10,
    paddingLeft: 10,
  },
  spacer: {
    minHeight: 65,
    [theme.breakpoints.up('md')]: {
      minHeight: 73,
    }
  },
  treeBox: {
    paddingLeft: 10,
    paddingRight: 10,
    overflow: 'auto',
  },
  version: {
    fontSize: 11,
    padding: 10,
  },
}))



export default Navigation
