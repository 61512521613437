export const convertArrayToIds = (array) => {
  const valueArray = array ? array : []
  const idArray = []
  valueArray.forEach(x => {idArray.push(x.id)})
  return idArray
}

export const extractIdFromObject = (obj) => {
  try {
    return obj.id
  } catch {
    return null
  }
}

/*
*  FROM:
*  [ { id: '11', name: '1100' },
*    { id: '22', name: '2200' } ];
*  TO:
*    { "11": "1000", "22": "2200" }
 */
export const flatObjectArray = (arr) => {
  return arr.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
}


//return an array of objects according to key, value, or key and value matching
export const getObjects = (obj, key, val) => {
  let objects = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] === 'object') {
      objects = objects.concat(getObjects(obj[i], key, val));
    } else
      //if key matches and value matches or if key matches and value is not passed
      // (eliminating the case where key matches but passed value does not)
    if ((i === key && obj[i] === val) || (i === key && val === '')) {
      objects.push(obj);
    } else if (obj[i] === val && key === ''){
      //only add if the object is not already in the array
      if (objects.lastIndexOf(obj) === -1){
        objects.push(obj);
      }
    }
  }
  return objects;
};

export const sortArrayByAttribute = (array, key) => {
  return array.sort((a,b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
}
